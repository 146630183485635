import React from 'react';
import styled, {css} from 'styled-components';
import {Typography} from '@fupa/fupa-uikit';

const backToOptionsHeight = '2.25rem';

const Wrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 0 1.25rem;
  @media screen and (min-width: 500px) {
    padding: 0 2.5rem;
  }
  ${(props) =>
    props.scrollable &&
    css`
      overflow: scroll;
    `}
`;

const Headline = styled(Typography)`
  margin: ${(props) => (props.setMargin ? `${backToOptionsHeight} 0 2rem 0` : '0 0 2rem 0')};
`;

const Subtitle = styled(Typography)`
  margin-top: ${(props) => (props.setMargin ? `${backToOptionsHeight}` : '0')};
`;

const MainLayout = ({backToOptions, title, subtitle, children, scrollable}) => {
  const sub = subtitle ? (
    <Subtitle setMargin={!backToOptions} variant='button-sub'>
      {subtitle}
    </Subtitle>
  ) : null;

  const showTitle = title ? (
    <Headline setMargin={!backToOptions && !subtitle} variant='headline2' component='h2'>
      {title}
    </Headline>
  ) : null;

  return (
    <Wrapper scrollable={scrollable}>
      {backToOptions}
      {sub}
      {showTitle}
      {children}
    </Wrapper>
  );
};

export {MainLayout};
